import { Alert, AlertColor, Snackbar } from '@mui/material';

type SnackbarTypes = {
  open: boolean;
  handleClose: () => void;
  message: string;
  severity?: AlertColor;
};

export default function SnackbarComponent({
  open,
  handleClose,
  message,
  severity,
}: SnackbarTypes) {
  const closeSnackbar = () => {
    handleClose();
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={closeSnackbar}>
      <Alert onClose={closeSnackbar} severity={severity ?? 'error'}>
        {message}
      </Alert>
    </Snackbar>
  );
}
