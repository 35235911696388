import { Container } from '@mui/material';
import EditUserForm from 'components/EditUserForm/EditUserForm';
import PageHeader from 'components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';

export default function EditUserPage() {
  const { t } = useTranslation();
  return (
    <Container>
      <PageHeader
        title={t('editUserPage.title')}
        hasButton={false}
        hasBackButton={true}
      />
      <EditUserForm />
    </Container>
  );
}
