import { alpha, BoxProps, styled } from '@mui/material';
import SimpleBarReact, { Props } from 'simplebar-react';

const RootStyle = styled('div')({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
});

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: '10px',
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: '6px',
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

export default function Scrollbar({ children, sx }: BoxProps & Props) {
  return (
    <RootStyle>
      <SimpleBarStyle sx={sx}>{children}</SimpleBarStyle>
    </RootStyle>
  );
}
