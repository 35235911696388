import styled from '@emotion/styled';
import { Card, Grid, Typography } from '@mui/material';

export const DetailText = styled(Typography)({
  textDecoration: 'underline',
  fontWeight: 600,
});

export const ContainerGrid = styled(Grid)({
  display: 'flex',
  '& :nth-child(3n+1)': {
    paddingLeft: '0px',
  },
  '& :nth-of-type(3n)': {
    paddingRight: '0px',
  },
});

export const StyledGrid = styled(Grid)({
  display: 'flex',
  flex: '0 1 33.33%',
  width: '100%',
  padding: '20px',
});

export const ChallengeCard = styled(Card)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '20px !important',
});

export const Header = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  paddingBottom: '10px',
});

export const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: '20px',
  paddingBottom: '20px',
});

export const smallPadding = {
  paddingBottom: '10px',
};
