import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getCompobots = async () => {
  return await api.get('compobots');
};

export const searchCompobots = async (searchTerm: string) => {
  return await api.get(`compobots/lookup/${searchTerm}`);
};

export const getCompobotById = async (id: string) => {
  return await api.get(`compobots/${id}`);
};

export const assignCompobot = async (orgId: string, deviceId: string) => {
  return await api.post(`compobots/organisation/${orgId}`, {
    deviceId: deviceId,
  });
};

export const reAssignCompobot = async (id: string, orgId: string) => {
  return await api.put(`compobots/${id}/organisation/${orgId}`);
};

export const deleteCompobot = async (id: string) => {
  return await api.delete(`compobots/${id}`);
};

export const deleteCompobotBulk = async (ids: string[]) => {
  return await api.deleteWithParams('compobots', { UIds: ids });
};
