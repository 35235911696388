import {
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getTribesTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  deleteTribe,
  deleteTribeBulk,
  getTribesForOrganisation,
  searchTribesForOrganisation,
} from 'services/tribe.service';
import { TribeType } from 'types/tribes.type';

export default function TribesPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [orgDetails, setOrgDetails] = useState({ orgName: '', orgId: '' });
  const [tribeList, setTribeList] = useState<TribeType[]>([]);
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getTribesForOrganisationHandler();
  }, [id]);

  const getTribesForOrganisationHandler = () => {
    if (id) {
      getTribesForOrganisation(id).then(({ data }) => {
        setOrgDetails({ orgName: data.companyName, orgId: data.id });
        setTribeList(data.tribes);
      });
    }
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName && id) {
        searchTribesForOrganisation(id, filterName).then((response) => {
          setTribeList(response.data);
        });
      } else {
        getTribesForOrganisationHandler();
      }
    }, 300),
    [],
  );
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      setSelected(tribeList.map((n: TribeType) => n.id));
      return;
    }
    setSelected([]);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteTribe = (tribeId: string) => {
    deleteTribe(tribeId)
      .then(() => {
        setTribeList(tribeList.filter(({ id }) => id !== tribeId));
        handleSelectAllClick(false);
        setOpenModal(false);
        handleOpen(t('tribesPage.tribeDeletedSuccessfullyMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteTribeBulk = (ids: string[]) => {
    deleteTribeBulk(ids)
      .then(() => {
        setTribeList(tribeList.filter(({ id }) => !ids.includes(id)));
        setOpenModal(false);
        handleSelectAllClick(false);
        handleOpen(t('tribesPage.tribesDeletedSuccessfullyMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteTribeAction = () => {
    if (selected.length > 1) {
      handleDeleteTribeBulk(selected);
    } else {
      handleDeleteTribe(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const tribesTableHead = useMemo(getTribesTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={`${orgDetails.orgName}: ${t('tribesPage.title')}`}
        buttonTitle={t('tribesPage.buttonTitle').toString()}
        hasButton
      />
      <Card>
        <TableToolBar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={tribesTableHead}
              rowCount={tribeList.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />

            <TableBody>
              {tribeList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, displayName, score } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => handleClick(id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ fontWeight: 600 }}
                          >
                            {displayName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{score}</TableCell>

                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          id={id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {!tribeList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tribeList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={() => handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteTribeAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
