import { CreateTribeType } from 'types/tribes.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getTribes = async () => {
  return await api.get('tribes');
};

export const getTribeById = async (id: string) => {
  return await api.get(`tribes/${id}`);
};

export const createTribe = async (orgId: string, tribe: CreateTribeType) => {
  return await api.post(`tribes/${orgId}`, tribe);
};

export const modifyTribe = async (tribeId: string, tribe: CreateTribeType) => {
  return await api.put(`tribes/${tribeId}`, tribe);
};

export const getTribesForOrganisation = async (orgId: string) => {
  return await api.get(`organisation/tribes/${orgId}`);
};

export const searchTribesForOrganisation = async (
  orgId: string,
  searchTerms: string,
) => {
  return await api.get(`organisation/tribes/${orgId}/lookup/${searchTerms}`);
};

export const deleteTribe = async (id: string) => {
  return await api.delete(`tribes/${id}`);
};

export const deleteTribeBulk = async (ids: string[]) => {
  return await api.deleteWithParams('tribes', { UIds: ids });
};
