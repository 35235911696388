import { EditUserType, UserType } from 'types/users.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getUsers = async () => {
  return await api.get('users');
};

export const searchUsers = async (searchTerm: string) => {
  return await api.get(`users/lookup/${searchTerm}`);
};

export const getUserById = async (id: string): Promise<UserType> => {
  return await api
    .get(`users/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
};

export const deleteUser = async (userId: string) => {
  return await api.delete(`admin/user/${userId}`);
};

export const deleteUserBulk = async (userId: string[]) => {
  return await api.deleteWithParams('users', { UIds: userId });
};

export const updateUser = async (id: string, user: EditUserType) => {
  return await api.put(`users/update/${id}`, user);
};
