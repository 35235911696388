import {
  alpha,
  Container,
  ListItemButton,
  ListSubheader,
  styled,
} from '@mui/material';
import { theme } from 'theme';

export const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  height: '50px',
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: '40px',
  paddingRight: '20px',
  color: theme.palette.text.secondary,
  '&:before': {
    top: '0px',
    right: '0px',
    width: '3px',
    bottom: '0px',
    // eslint-disable-next-line quotes
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ListItemIconStyle = styled(Container)({
  width: '22px',
  height: '22px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledSubHeader = styled(ListSubheader)({
  textTransform: 'uppercase',
  fontWeight: '600',
});

export const activeRootStyle = {
  color: 'primary.main',
  fontWeight: 'fontWeightMedium',
  bgcolor: alpha(
    theme.palette.primary.main,
    theme.palette.action.selectedOpacity,
  ),

  '&:before': { display: 'block' },
};

export const activeSubStyle = {
  color: 'text.primary',
  fontWeight: 'fontWeightMedium',
};
