import {
  Button,
  Card,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import ErrorText from 'components/ErrorText/ErrorText';
import SnackbarContext from 'contexts/snackbarContext';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserById, updateUser } from 'services/users.services';
import { EditUserType } from 'types/users.type';
import { useTranslation } from 'react-i18next';

const CustomForm = styled('form')`
  width: 100%;
`;

export default function EditUserForm() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<EditUserType>();
  const { handleOpen } = useContext(SnackbarContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [organisation, setOrganisation] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getUserById(id)
        .then((user) => {
          setOrganisation(user.organisation.companyName);
          setValue('firstname', user.firstname);
          setValue('lastname', user.lastname);
          setValue('displayName', user.displayName);

        })
        .catch(({ data }) => handleOpen(data.message));
    }
  }, [id]);

  const onSubmit = ({ firstname, lastname, displayName }: EditUserType) => {
    const userObject = {
      firstname,
      lastname,
      displayName,
    };
    if (id) {
      updateUser(id, userObject)
        .then(() => {
          navigate('/users');
          handleOpen(t('editUserForm.successMessage'), 'success');
        })
        .catch(({ data }) => handleOpen(data.message));
    }
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="firstname"
              defaultValue=""
              rules={{
                required: true,
                pattern: /^(?!\s)[a-zA-Z\sáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/,
                maxLength: 35,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.firstName')}
                  type="text"
                  error={errors.firstname ? true : false}
                />
              )}
            />
            {errors.firstname && errors.firstname.type === 'pattern' && (
              <ErrorText>{t('formErrors.lastNameInvalid')}</ErrorText>
            )}
            {errors.firstname && errors.firstname.type === 'required' && (
              <ErrorText>{t('formErrors.firstNameRequired')}</ErrorText>
            )}
            {errors.firstname && errors.firstname.type === 'maxLength' && (
              <ErrorText>{t('formErrors.firstNameTooLong')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="lastname"
              defaultValue=""
              rules={{
                required: true,
                pattern: /^(?!\s)[a-zA-Z\sáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/,
                maxLength: 35,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.lastName')}
                  type="text"
                  error={errors.lastname ? true : false}
                />
              )}
            />
            {errors.lastname && errors.lastname.type === 'required' && (
              <ErrorText>{t('formErrors.lastNameRequired')}</ErrorText>
            )}
            {errors.lastname && errors.lastname.type === 'pattern' && (
              <ErrorText>{t('formErrors.lastNameInvalid')}</ErrorText>
            )}
            {errors.lastname && errors.lastname.type === 'maxLength' && (
              <ErrorText>{t('formErrors.lastNameTooLong')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="displayName"
              defaultValue=""
              rules={{
                required: true,
                pattern: /^(?!\s)[a-zA-Z\sáéíóöőúüűÁÉÍÓÖŐÚÜŰ]+$/,
                maxLength: 35,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.displayName')}
                  type="text"
                  error={errors.firstname ? true : false}
                />
              )}
            />
            {errors.displayName && errors.displayName.type === 'required' && (
              <ErrorText>{t('formErrors.displayNameRequired')}</ErrorText>
            )}
            {errors.displayName && errors.displayName.type === 'pattern' && (
              <ErrorText>{t('formErrors.displayNameInvalid')}</ErrorText>
            )}
            {errors.displayName && errors.displayName.type === 'maxLength' && (
              <ErrorText>{t('formErrors.displayNameTooLong')}</ErrorText>
            )}
          </Grid>
        </Grid>
        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">
          {t('formFields.submitButtonLabel')}
        </Button>
      </CustomForm>
    </Card>
  );
}
