import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { Box, BoxProps, List, ListItemText } from '@mui/material';
import {
  activeRootStyle,
  ListItemIconStyle,
  ListItemStyle,
  StyledSubHeader,
} from './NavSection.style';

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

function NavItem({ item }: { item: NavItemProps }) {
  const { pathname } = useLocation();
  const { title, path, icon, info } = item;
  const isActiveRoot = path
    ? !!matchPath({ path, end: false }, pathname) && path !== '/'
    : false;

  return (
    <ListItemStyle
      disableGutters
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon}</ListItemIconStyle>
      <>
        <ListItemText disableTypography primary={title} />
        {info}
      </>
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  navConfig: {
    subheader: string;
    items: NavItemProps[];
  }[];
}

export default function NavSection({ navConfig, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            <StyledSubHeader>{subheader}</StyledSubHeader>
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
