import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type ErrorTextType = {
  children: ReactNode;
};

export default function ErrorText({ children }: ErrorTextType) {
  return (
    <Typography
      sx={{ color: 'error.main', marginTop: '5px', fontSize: '12px' }}
    >
      {children}
    </Typography>
  );
}
