import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

type LoginUser = {
  email: string;
  password: string;
};

export const login = async (user: LoginUser) => {
  return await api.put('admin/login', user);
};
