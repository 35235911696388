import { Box, Button, Container, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getExpotedLeads } from 'services/leads.service';

const RootStyle = styled(Container)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function LeadsPage() {
  const { t } = useTranslation();

  const getExportedLeads = async () => {
    const response = await getExpotedLeads();
    const data = response.data; // Extract data from AxiosResponse

    // Create and download csv file
    const element = document.createElement('a');
    const file = new Blob([data], { type: 'text/csv' }); // Use data directly if it's already a CSV string
    element.href = URL.createObjectURL(file);
    element.download = 'leads.csv';
    document.body.appendChild(element);
    element.click();
  };

  return (
    <RootStyle>
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            {t('leadsPage.header')}
          </Typography>
          <Typography sx={{ color: 'text.primary' }}>
            {t('leadsPage.subheader')}
          </Typography>

          <Button
            onClick={getExportedLeads}
            size="large"
            variant="contained"
            style={{ marginTop: '50px', width: '80%' }}
          >
            {t('leadsPage.buttonTitle')}
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
