type ImgProps = {
  src: string;
  onClick?: () => void;
};

const Img = ({ src, onClick }: ImgProps) => {
  return (
    <img
      src={require(`assets/${src}`)}
      onClick={onClick}
      style={{ width: '100%' }}
    ></img>
  );
};

export default Img;
