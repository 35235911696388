import { Typography } from '@mui/material';

type WordCounterType = {
  currentWordCount: number;
  maxCount: number;
};

export default function WordCounter({
  currentWordCount,
  maxCount,
}: WordCounterType) {
  return (
    <Typography
      sx={{
        color: currentWordCount <= maxCount ? 'text.secondary' : 'error.main',
        display: 'flex',
        fontSize: 12,
        alignSelf: 'flex-end',
      }}
    >
      {`${currentWordCount}/${maxCount}`}
    </Typography>
  );
}
