import styled from '@emotion/styled';

export const CustomForm = styled('form')({
  width: '100%',
});

export const FormHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: '20px',
});

export const ImageWrapper = styled('div')({
  width: '65px',
});
