import { Box, Drawer, Stack, styled, Typography } from '@mui/material';
import MHidden from 'components/@material-extend/MHidden';
import Img from 'components/Img/Img';
import NavSection from 'components/NavSection/NavSection';
import Scrollbar from 'components/Scrollbar/Scrollbar';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import getSidebarConfig from 'layouts/DashboardLayout/SidebarConfig';
import { useEffect } from 'react';
import { AccountStyle } from './DashboardLayout.style';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex,
    }),
  },
}));

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: DashboardSidebarProps) {
  const { collapseClick } = useCollapseDrawer();
  const { t } = useTranslation();
  const sidebarConfig = getSidebarConfig();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box sx={{ display: 'inline-flex', width: '65px' }}>
            <Img src={'compocity-logo.png'} />
          </Box>
        </Stack>

        <AccountStyle>
          <Box sx={{ width: '40px' }}>
            <Img src={'admin-icon.png'} />
          </Box>
          <Stack sx={{ ml: 2 }}>
            <Typography
              variant="subtitle2"
              sx={{ color: 'text.primary', fontWeight: '600' }}
            >
              {t('sidebar.adminTitle')}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('sidebar.adminLabel')}
            </Typography>
          </Stack>
        </AccountStyle>
      </Stack>
      <NavSection navConfig={sidebarConfig} />
      <LanguageSwitcher />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
          onClose={onCloseSidebar}
          open={isOpenSidebar}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
