import { Container } from '@mui/material';
import CreateNotificationForm from 'components/CreateNotificationForm/CreateNotificationForm';
import PageHeader from 'components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function CreateNotificationPage() {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <Container>
      <PageHeader
        title={
          id
            ? t('createNotificationPage.titleModify')
            : t('createNotificationPage.titleCreate')
        }
        hasButton={false}
        hasBackButton={true}
      />
      <CreateNotificationForm />
    </Container>
  );
}
