import { createTheme } from '@mui/material';
import ComponentsOverrides from './overrides';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3EB185',
    },
    error: {
      main: '#d32f2f',
    },
  },
});

theme.components = ComponentsOverrides(theme);
