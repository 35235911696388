import React from 'react';
import { StyledSubHeader } from 'components/NavSection/NavSection.style';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { ReactComponent as GbFlag } from '../../assets/gb_flag.svg';
import { ReactComponent as HuFlag } from '../../assets/hu_flag.svg';
import i18n from 'translations/i18n';

const LanguageSwitcher = ({ isLogin }: { isLogin?: boolean }) => {
  const { t } = useTranslation();

  const handleLanguageChange = (lang: 'hu' | 'en') => {
    const currentLang = i18n.language;

    if (currentLang !== lang) {
      i18n.changeLanguage(lang);
      localStorage.setItem('lang', lang);
    }
  };

  return (
    <Stack>
      {!isLogin && (
        <StyledSubHeader>{t('sidebar.menuHeaderLang')}</StyledSubHeader>
      )}
      <Stack
        direction="row"
        spacing={2}
        sx={{
          pl: isLogin ? 0 : 6,
          mb: isLogin ? 2 : 0,
        }}
      >
        <Button
          onClick={() => handleLanguageChange('en')}
          sx={{
            minWidth: 0,
            width: 36,
            height: 20,
            padding: 0,
          }}
        >
          <GbFlag width="100%" height="auto" />
        </Button>
        <Button
          onClick={() => handleLanguageChange('hu')}
          sx={{
            minWidth: 0,
            width: 36,
            height: 20,
            padding: 0,
          }}
        >
          <HuFlag width="100%" height="auto" />
        </Button>
      </Stack>
    </Stack>
  );
};

export default LanguageSwitcher;
