import DashboardLayout from 'layouts/DashboardLayout';
import AssignCompobotPage from 'pages/AssignCompobotPage/AssignCompobotPage';
import ChallengesPage from 'pages/ChallengesPage/ChallengesPage';
import CompobotsPage from 'pages/CompoBotsPage/CompoBotsPage';
import CreateChallengePage from 'pages/CreateChallengePage/CreateChallengePage';
import CreateNotificationPage from 'pages/CreateNotificationPage/CreateNotificationPage';
import CreateOrganisationPage from 'pages/CreateOrganizationPage/CreateOrganizationPage';
import CreateTribePage from 'pages/CreateTribePage/CreateTribePage';
import EditUserPage from 'pages/EditUserPage/EditUserPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import LeadsPage from 'pages/LeadsPage/LeadsPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import NotificationsPage from 'pages/NotificationsPage/NotificationsPage';
import OrganisationsPage from 'pages/OrganizationsPage/OrganizationsPage';
import TribesPage from 'pages/TribesPage/TribesPage';
import UsersPage from 'pages/UsersPage/UsersPage';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'users', element: <UsersPage /> },
      { path: 'users/:id', element: <EditUserPage /> },
      {
        path: 'organisations',
        element: <OrganisationsPage />,
      },
      {
        path: 'organisations/new',
        element: <CreateOrganisationPage />,
      },
      {
        path: 'organisations/:id',
        element: <CreateOrganisationPage />,
      },
      {
        path: 'organisations/:id/tribes',
        element: <TribesPage />,
      },

      {
        path: 'organisations/:id/tribes/new',
        element: <CreateTribePage />,
      },

      {
        path: 'organisations/:id/tribes/:tribeId',
        element: <CreateTribePage />,
      },
      {
        path: 'leads',
        element: <LeadsPage />,
      },

      { path: 'compobots', element: <CompobotsPage /> },
      { path: 'compobots/new', element: <AssignCompobotPage /> },
      { path: 'compobots/:id', element: <AssignCompobotPage /> },
      { path: 'challenges', element: <ChallengesPage /> },
      { path: 'challenges/:id', element: <CreateChallengePage /> },
      { path: 'challenges/new', element: <CreateChallengePage /> },
      { path: 'notifications', element: <NotificationsPage /> },
      { path: 'notifications/:id', element: <CreateNotificationPage /> },
      { path: 'notifications/new', element: <CreateNotificationPage /> },
    ],
  },
]);
