import {
  Button,
  Card,
  Checkbox,
  colors,
  FormControlLabel,
  Grid,
  styled,
  TextField,
} from '@mui/material';
import ErrorText from 'components/ErrorText/ErrorText';
import TagsInput from 'components/TagsInput/TagsInput';
import SnackbarContext from 'contexts/snackbarContext';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createOrganisation,
  getOrganisationById,
  modifyOrganisation,
} from 'services/organisation.service';
import { CreateOrganisationType } from 'types/organisations.type';

type CreateOrganisationFormType = {
  companyName: string;
  contactEmail: string;
  zipCode: string;
  city: string;
  address: string;
  isOpen?: boolean;
};

const CustomForm = styled('form')`
  width: 100%;
`;

export default function CreateOrganizationForm() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<CreateOrganisationFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [tribes, setCurrentTribes] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getOrganisationById(id).then(({ data }) => {
        const addressArray = data.address.split(',');
        setValue('companyName', data.companyName);
        setValue('contactEmail', data.contactEmail);
        setValue('city', addressArray[0].trim());
        setValue('zipCode', addressArray[1].trim());
        setValue('address', addressArray[2].trim());
        setIsOpen(data.isOpen);
      });
    }
  }, [id]);

  const onSubmit = (data: CreateOrganisationFormType) => {
    const emailHandler = data.contactEmail.split('@')[1];
    const orgObject: CreateOrganisationType = {
      companyName: data.companyName,
      contactEmail: data.contactEmail,
      emailHandler,
      address: `${data.zipCode}, ${data.city}, ${data.address}`,
      isOpen,
    };
    if (tribes.length) {
      orgObject['tribes'] = tribes;
    }

    if (!id) {
      createOrganisation(orgObject)
        .then(() => {
          setCurrentTribes([]);
          navigate('/organisations');
          handleOpen(
            t('organisationsForm.orgCreatedSuccessfullyMessage'),
            'success',
          );
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {
      modifyOrganisation(id, orgObject)
        .then(() => {
          navigate('/organisations');
          handleOpen(
            t('organisationsForm.orgModifiedSuccessfullyMessage'),
            'success',
          );
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  };

  const setTribes = (tribes: string[]): string[] => {
    setCurrentTribes(tribes);
    return tribes;
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="companyName"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.companyName')}
                  type="text"
                  error={errors.companyName ? true : false}
                />
              )}
            />
            {errors.companyName && (
              <ErrorText>{t('formErrors.companyNameRequired')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="contactEmail"
              defaultValue=""
              rules={{
                required: true,
                pattern: /^\w+([.-]?\w+)*@\w+(-]?\w+)*(\.\w{2,3})+$/,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.contactEmail')}
                  type="text"
                  error={errors.contactEmail ? true : false}
                />
              )}
            />
            {errors.contactEmail && errors.contactEmail.type === 'required' && (
              <ErrorText>{t('formErrors.contactEmailRequired')}</ErrorText>
            )}
            {errors.contactEmail && errors.contactEmail.type === 'pattern' && (
              <ErrorText>{t('formErrors.contactEmailPattern')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="zipCode"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.zipCode')}
                  type="text"
                  error={errors.zipCode ? true : false}
                />
              )}
            />
            {errors.zipCode && (
              <ErrorText>{t('formErrors.zipCodeRequired')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="city"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.city')}
                  type="text"
                  error={errors.city ? true : false}
                />
              )}
            />
            {errors.city && (
              <ErrorText>{t('formErrors.cityRequired')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="address"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('formFields.address')}
                  type="text"
                  error={errors.address ? true : false}
                />
              )}
            />
            {errors.address && (
              <ErrorText>{t('formErrors.addressRequired')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="isOpen"
              render={({ field }) => (
                <FormControlLabel
                  sx={{
                    border: '1px solid black',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    borderColor: colors.grey[400],
                    borderRadius: '5px',
                    color: colors.grey[700],
                    '&:hover': {
                      borderColor: 'black',
                    },
                  }}
                  control={
                    <Checkbox
                      {...field}
                      checked={isOpen}
                      value={isOpen}
                      onChange={() => setIsOpen((prev) => !prev)}
                    />
                  }
                  label={t('formFields.isOpen')}
                />
              )}
            />
          </Grid>
          {!id && (
            <Grid item xs={12}>
              <TagsInput setTribesHandler={setTribes} tribes={tribes} />
            </Grid>
          )}
        </Grid>
        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">
          {t('formFields.submitButtonLabel')}
        </Button>
      </CustomForm>
    </Card>
  );
}
