import { Box, styled } from '@mui/material';
import LoginForm from 'components/LoginForm/LoginForm';

const LoginBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '400px',
  width: '600px',
  padding: '40px 60px',
  borderRadius: '20px',
  boxShadow: '0 7px 20px 0 rgba(0, 0, 0, 0.1)',
});

const LoginContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fafafa',
});

export default function LoginPage() {
  return (
    <LoginContainer>
      <LoginBox>
        <LoginForm />
      </LoginBox>
    </LoginContainer>
  );
}
