import { Container } from '@mui/material';
import AssignCompobotForm from 'components/AssignCompobotForm/AssignCompobotForm';
import PageHeader from 'components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';

export default function AssignCompobotPage() {
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeader
        title={t('assignCompobotPage.title')}
        hasButton={false}
        hasBackButton={true}
      />
      <AssignCompobotForm />
    </Container>
  );
}
