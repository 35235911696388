import { PaperProps, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SearchNotFoundProps extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({
  searchQuery = '',
  ...other
}: SearchNotFoundProps) {
  const { t } = useTranslation();
  return (
    <Stack {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        {t('search.notFound')}
      </Typography>
      {searchQuery && (
        <Typography variant="body2" align="center">
          No results found for &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or
          using complete words.
        </Typography>
      )}
    </Stack>
  );
}
