import { Button, Card, Grid, styled, TextField } from '@mui/material';
import ErrorText from 'components/ErrorText/ErrorText';
import SnackbarContext from 'contexts/snackbarContext';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createTribe, getTribeById, modifyTribe } from 'services/tribe.service';
import { CreateTribeType } from 'types/tribes.type';

const CustomForm = styled('form')`
  width: 100%;
`;

export default function CreateTribeForm() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<CreateTribeType>();

  const { id, tribeId } = useParams();
  const { handleOpen } = useContext(SnackbarContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (tribeId) {
      getTribeById(tribeId)
        .then(({ data }) => {
          setValue('displayName', data.displayName);
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  }, []);

  const onSubmit = (displayName: CreateTribeType) => {
    if (tribeId) {
      modifyTribe(tribeId, displayName)
        .then(() => {
          navigate(-1);
          handleOpen(
            t('createTribeForm.tribeModifiedSuccessMessage'),
            'success',
          );
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else if (id) {
      createTribe(id, displayName)
        .then(() => {
          reset();
          handleOpen(
            t('createTribeForm.tribeCreatedSuccessMessage'),
            'success',
          );
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {
      return;
    }
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '600px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="displayName"
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.displayName ? true : false}
                  fullWidth
                  autoComplete="text"
                  type="text"
                  label={t('formFields.displayName')}
                />
              )}
            />

            {errors.displayName && (
              <ErrorText>{t('formErrors.displayNameRequired')}</ErrorText>
            )}
          </Grid>
        </Grid>
        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">
          {t('formFields.submitButtonLabel')}
        </Button>
      </CustomForm>
    </Card>
  );
}
