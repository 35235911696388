import { Theme } from '@mui/material';

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
          color: theme.palette.common.white,
          fontWeight: 600,
          borderRadius: 8,
        },
        sizeLarge: {
          height: 48,
        },
        outlined: {
          color: theme.palette.primary.main,
        },
      },
    },
  };
}
