import { Container } from '@mui/material';
import CreateTribeForm from 'components/CreateTribeForm/CreateTribeForm';
import PageHeader from 'components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function CreateTribePage() {
  const { tribeId } = useParams();
  const { t } = useTranslation();

  return (
    <Container>
      <PageHeader
        title={
          tribeId
            ? t('createTribePage.titleModify')
            : t('createTribePage.titleCreate')
        }
        hasButton={false}
        hasBackButton={true}
      />
      <CreateTribeForm />
    </Container>
  );
}
