import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {
  InputAdornment,
  OutlinedInput,
  styled,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: '8px' },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500]} !important`,
  },
}));

type TableToolbarProps = {
  numSelected: number;
  onFilterName: (value: string) => void;
  deleteHandler: () => void;
};

export default function TableToolbar({
  numSelected,
  onFilterName,
  deleteHandler,
}: TableToolbarProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} {t('toolbar.selected')}
        </Typography>
      ) : (
        <SearchStyle
          onChange={(e) => onFilterName(e.target.value)}
          placeholder={t('toolbar.searchPlaceholder').toString()}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon></SearchIcon>
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 && (
        <Tooltip title="Delete" onClick={deleteHandler}>
          <DeleteIcon />
        </Tooltip>
      )}
    </RootStyle>
  );
}
