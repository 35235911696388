/* eslint-disable @typescript-eslint/no-empty-function */
import { AlertColor } from '@mui/material';
import React, { createContext, useState } from 'react';

type SnackbarContextType = {
  open: boolean;
  message: string;
  type: AlertColor;
  handleClose: () => void;
  handleOpen: (message: string, type?: AlertColor) => void;
};

const initialState: SnackbarContextType = {
  open: false,
  message: '',
  type: 'error',
  handleClose: () => {},
  handleOpen: () => {},
};

const SnackbarContext = createContext<SnackbarContextType>(initialState);

export function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const [open, isOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState<AlertColor>('error');

  const handleClose = () => {
    isOpen(false);
  };

  const handleOpen = (message: string, type?: AlertColor) => {
    setType(type ?? 'error');
    setMessage(message);
    isOpen(true);
  };

  return (
    <SnackbarContext.Provider
      value={{
        open,
        message,
        type,
        handleClose,
        handleOpen,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}

export default SnackbarContext;
