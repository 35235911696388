/* eslint-disable indent */
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';

import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ErrorText from 'components/ErrorText/ErrorText';
import SnackbarContext from 'contexts/snackbarContext';
import { addDays, addMonths, endOfDay, startOfDay } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createChallenge,
  getChallengeById,
  modifyChallenge,
} from 'services/challenge.service';
import { getOrganizations } from 'services/organisation.service';
import { theme } from 'theme';
import { ChallengeType, CreateChallengeType } from 'types/challenges.type';
import { OrganisationType } from 'types/organisations.type';

import WordCounter from '../WordCounter/WordCounter';

const SHORT_DESC_MAX_LENGTH = 100;
const LONG_DESC_MAX_LENGTH = 240;

export default function CreateChallengeForm() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<CreateChallengeType>();
  const [organisationList, setOrganisationList] = useState([]);
  const navigate = useNavigate();
  const { handleOpen } = useContext(SnackbarContext);
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  const [shortDescriptionHUCount, setShortDescriptionHUCount] = useState(0);
  const [shortDescriptionENCount, setShortDescriptionENCount] = useState(0);
  const [longDescriptionHUCount, setLongDescriptionHUCount] = useState(0);
  const [longDescriptionENCount, setLongDescriptionENCount] = useState(0);

  const shortDescriptionChangeHandler = (value: string, lang: 'hu' | 'en') => {
    const count = value.length;

    if (lang === 'hu') {
      setShortDescriptionHUCount(count);
    }

    if (lang === 'en') {
      setShortDescriptionENCount(count);
    }
  };

  const longDescriptionChangeHandler = (value: string, lang: 'hu' | 'en') => {
    const count = value.length;

    if (lang === 'hu') {
      setLongDescriptionHUCount(count);
    }

    if (lang === 'en') {
      setLongDescriptionENCount(count);
    }
  };

  useEffect(() => {
    getOrganizations().then((response) => {
      setOrganisationList(response.data);
    });

    if (id) {
      getChallengeById(id).then(({ data }: { data: ChallengeType }) => {
        setValue('name_HU', data.name_HU);
        setValue('name_EN', data.name_EN);
        setValue('shortDescription_HU', data.shortDescription_HU);
        setValue('shortDescription_EN', data.shortDescription_EN);
        setValue('longDescription_HU', data.longDescription_HU);
        setValue('longDescription_EN', data.longDescription_EN);
        setValue('targetScore', data.targetScore);
        setValue('startDate', new Date(data.startDate));
        setValue('endDate', new Date(data.endDate));
        setValue('organisationId', data.organisation.id);
        setShortDescriptionHUCount(data.shortDescription_HU.length);
        setShortDescriptionENCount(data.shortDescription_EN.length);
        setLongDescriptionHUCount(data.longDescription_HU.length);
        setLongDescriptionENCount(data.longDescription_EN.length);
      });
    }
  }, []);

  const onSubmit = (challenge: CreateChallengeType) => {
    const orgId = challenge.organisationId;

    if (id) {
      delete challenge.organisationId;
      // if the challange start date already passed, we will update it to submit date.now()
      // 1991.01.01 is to help understanding the past time + typescript happiness
      if (
        new Date(challenge.startDate ?? new Date('1990.01.01')) < new Date()
      ) {
        challenge.startDate = undefined;
      }

      modifyChallenge(id, challenge)
        .then(() => {
          handleOpen('Challenge successfully modified', 'success');
          navigate('/challenges');
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {
      createChallenge(orgId as string, challenge)
        .then(() => {
          handleOpen('Challenge successfully created', 'success');
          navigate('/challenges');
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  };

  return (
    <Container style={{ maxWidth: 1200 }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="name_HU"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                maxLength: 30,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.name_HU ? true : false}
                  fullWidth
                  autoComplete="text"
                  type="text"
                  label={t('formFields.name_hun')}
                />
              )}
            />
            {errors.name_HU && errors.name_HU.type === 'required' && (
              <ErrorText>{t('formErrors.nameRequired')}</ErrorText>
            )}
            {errors.name_HU && errors.name_HU.type === 'maxLength' && (
              <ErrorText>{t('formErrors.nameTooLong')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="name_EN"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                maxLength: 30,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.name_EN ? true : false}
                  fullWidth
                  autoComplete="text"
                  type="text"
                  label={t('formFields.name_eng')}
                />
              )}
            />
            {errors.name_EN && errors.name_EN.type === 'required' && (
              <ErrorText>{t('formErrors.nameRequired')}</ErrorText>
            )}
            {errors.name_EN && errors.name_EN.type === 'maxLength' && (
              <ErrorText>{t('formErrors.nameTooLong')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="shortDescription_HU"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                maxLength: SHORT_DESC_MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.shortDescription_HU ? true : false}
                  fullWidth
                  type="text"
                  label={t('formFields.shortDescription_hun')}
                  multiline
                  rows={2}
                  onChange={(e) => {
                    field.onChange(e);
                    shortDescriptionChangeHandler(e.target.value, 'hu');
                  }}
                />
              )}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <WordCounter
                currentWordCount={shortDescriptionHUCount}
                maxCount={SHORT_DESC_MAX_LENGTH}
              />
              {errors.shortDescription_HU &&
                errors.shortDescription_HU.type === 'required' && (
                  <ErrorText>
                    {t('formErrors.shortDescriptionRequired')}
                  </ErrorText>
                )}

              {errors.shortDescription_HU &&
                errors.shortDescription_HU.type === 'maxLength' && (
                  <ErrorText>
                    {t('formErrors.shortDescriptionTooLong')}
                  </ErrorText>
                )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="shortDescription_EN"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                maxLength: SHORT_DESC_MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.shortDescription_EN ? true : false}
                  fullWidth
                  type="text"
                  label={t('formFields.shortDescription_eng')}
                  multiline
                  rows={2}
                  onChange={(e) => {
                    field.onChange(e);
                    shortDescriptionChangeHandler(e.target.value, 'en');
                  }}
                />
              )}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <WordCounter
                currentWordCount={shortDescriptionENCount}
                maxCount={SHORT_DESC_MAX_LENGTH}
              />
              {errors.shortDescription_EN &&
                errors.shortDescription_EN.type === 'required' && (
                  <ErrorText>
                    {t('formErrors.shortDescriptionRequired')}
                  </ErrorText>
                )}

              {errors.shortDescription_EN &&
                errors.shortDescription_EN.type === 'maxLength' && (
                  <ErrorText>
                    {t('formErrors.shortDescriptionTooLong')}
                  </ErrorText>
                )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="longDescription_HU"
              control={control}
              defaultValue=""
              rules={{
                maxLength: LONG_DESC_MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.longDescription_HU ? true : false}
                  fullWidth
                  type="text"
                  label={t('formFields.longDescription_hun')}
                  multiline
                  rows={4}
                  onChange={(e) => {
                    field.onChange(e);
                    longDescriptionChangeHandler(e.target.value, 'hu');
                  }}
                />
              )}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <WordCounter
                currentWordCount={longDescriptionHUCount}
                maxCount={LONG_DESC_MAX_LENGTH}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="longDescription_EN"
              control={control}
              defaultValue=""
              rules={{
                maxLength: LONG_DESC_MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.longDescription_EN ? true : false}
                  fullWidth
                  type="text"
                  label={t('formFields.longDescription_eng')}
                  multiline
                  rows={4}
                  onChange={(e) => {
                    field.onChange(e);
                    longDescriptionChangeHandler(e.target.value, 'en');
                  }}
                />
              )}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <WordCounter
                currentWordCount={longDescriptionENCount}
                maxCount={LONG_DESC_MAX_LENGTH}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="targetScore"
              control={control}
              defaultValue={1000}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  error={errors.targetScore ? true : false}
                  fullWidth
                  label={t('formFields.targetScore')}
                  InputProps={{ inputProps: { min: 1, max: 999999999 } }}
                />
              )}
            />
            {errors.targetScore && (
              <ErrorText>{t('formErrors.targetScoreRequired')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{ fontSize: 12, color: theme.palette.grey[600] }}
            >
              {t('formFields.startDate')}
            </Typography>
            <Controller
              control={control}
              name="startDate"
              defaultValue={startOfDay(new Date())}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ? startOfDay(value as Date) : value}
                  onChange={onChange}
                  minDate={watchStartDate ?? new Date()}
                  maxDate={watchEndDate}
                  disabled={
                    watchStartDate &&
                    (watchStartDate < addDays(new Date(), -1) ? true : false)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{ fontSize: 12, color: theme.palette.grey[600] }}
            >
              {t('formFields.endDate')}
            </Typography>
            <Controller
              control={control}
              name="endDate"
              defaultValue={endOfDay(addMonths(new Date(), 1))}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ? endOfDay(value as Date) : value}
                  onChange={onChange}
                  minDate={watchStartDate}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">
                {t('formFields.organisation')}
              </InputLabel>
              <Controller
                name="organisationId"
                control={control}
                rules={{
                  required: t('formErrors.organisationRequired').toString(),
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    label="Select Organisation"
                    labelId="label"
                    error={errors.organisationId ? true : false}
                  >
                    {organisationList.map((organisation: OrganisationType) => {
                      return (
                        <MenuItem
                          key={organisation?.id}
                          value={organisation?.id}
                        >
                          {organisation?.companyName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                defaultValue=""
              />
            </FormControl>
            {errors.organisationId && (
              <ErrorText>{t('formErrors.organisationRequired')}</ErrorText>
            )}
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{ marginTop: '20px' }}
          type="submit"
          disabled={!watchStartDate || !watchEndDate}
        >
          {t('formFields.submitButtonLabel')}
        </Button>
      </form>
    </Container>
  );
}
