import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, styled, Typography } from '@mui/material';
import PageNotFoundIllustration from 'assets/PageNotFoundIllustration';

const RootStyle = styled(Container)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <RootStyle>
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>

          <PageNotFoundIllustration
            sx={{ height: 260, my: { xs: 5, sm: 10 } }}
          />

          <Button onClick={() => navigate(-1)} size="large" variant="contained">
            Go back
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
