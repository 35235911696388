import SnackbarComponent from 'components/Snackbar/Snackbar';
import SnackbarContext from 'contexts/snackbarContext';
import { Fragment, useContext } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes';
import './App.css';
import './translations/i18n';

function App() {
  const { open, message, type, handleClose } = useContext(SnackbarContext);
  return (
    <Fragment>
      <RouterProvider router={router} />
      <SnackbarComponent
        open={open}
        message={message}
        handleClose={handleClose}
        severity={type}
      />
    </Fragment>
  );
}

export default App;
