import { CreateOrganisationType } from 'types/organisations.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getOrganizations = async () => {
  return await api.get('organisation');
};

export const searchOrganisations = async (searchTerm: string) => {
  return await api.get(`organisation/lookup/${searchTerm}`);
};

export const getOrganisationById = async (id: string) => {
  return await api.get(`organisation/${id}`);
};

export const createOrganisation = async (
  organisation: CreateOrganisationType,
) => {
  return await api.post('organisation', organisation);
};

export const deleteOrganisation = async (orgId: string) => {
  return await api.delete(`organisation/${orgId}`);
};

export const deleteOrganisationBulk = async (orgIds: string[]) => {
  return await api.deleteWithParams('organisation', { UIds: orgIds });
};

export const modifyOrganisation = async (
  orgId: string,
  organisation: CreateOrganisationType,
) => {
  return await api.put(`organisation/${orgId}`, organisation);
};

export const generatePin = async (orgId: string) => {
  return await api.put(`organisation/${orgId}/regenerate-pin`);
};
