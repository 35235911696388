import i18n from 'translations/i18n';

export const getOrganisationTableHead = () => [
  { id: 'company', label: i18n.t('tableHeaders.company'), alignRight: false },
  {
    id: 'contact-email',
    label: i18n.t('tableHeaders.contactEmail'),
    alignRight: false,
  },
  {
    id: 'email-handler',
    label: i18n.t('tableHeaders.emailHandler'),
    alignRight: false,
  },
  { id: 'users', label: i18n.t('tableHeaders.users'), alignRight: false },
  { id: 'tribes', label: i18n.t('tableHeaders.tribes'), alignRight: false },
  {
    id: 'isOpen',
    label: i18n.t('organisationsPage.isOpen'),
    alignRight: false,
    numeric: false,
    boolean: true,
  },
  { id: 'address', label: i18n.t('tableHeaders.address'), alignRight: false },
  { id: '' },
];

export const getUsersTableHead = () => [
  { id: 'name', label: i18n.t('tableHeaders.name'), alignRight: false },
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'email', label: i18n.t('tableHeaders.email'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: '' },
];

export const getCompobotsTableHead = () => [
  { id: 'deviceId', label: i18n.t('tableHeaders.deviceId'), alignRight: false },
  {
    id: 'organisation',
    label: i18n.t('tableHeaders.organisation'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: '' },
];

export const getTribesTableHead = () => [
  {
    id: 'displayName',
    label: i18n.t('tableHeaders.displayName'),
    alignRight: false,
  },
  { id: 'score', label: i18n.t('tableHeaders.score'), alignRight: false },
  { id: '' },
];

export const getNotificationsTableHead = () => [
  { id: 'title', label: i18n.t('tableHeaders.title'), alignRight: false },
  {
    id: 'description',
    label: i18n.t('tableHeaders.description'),
    alignRight: false,
  },
  { id: 'type', label: i18n.t('tableHeaders.type'), alignRight: false },
  {
    id: 'timestamp',
    label: i18n.t('tableHeaders.scheduledAt'),
    alignRight: false,
  },
  {
    id: 'delivered',
    label: i18n.t('tableHeaders.delivered'),
    alignRight: false,
  },
  {
    id: 'targetType',
    label: i18n.t('tableHeaders.targetType'),
    alignRight: false,
  },
  {
    id: 'numberOfTargets',
    label: i18n.t('tableHeaders.numberOfTargets'),
    alignRight: false,
  },
  { id: '' },
];
