import { Chip, Paper, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type TagsInputType = {
  setTribesHandler: (text: string[]) => string[];
  tribes: string[];
};

export default function TagsInput({ setTribesHandler, tribes }: TagsInputType) {
  const { t } = useTranslation();
  const [textFieldValue, setTextFieldValue] = useState('');

  const handleDelete = (name: string) => () => {
    setTribesHandler(tribes.filter((chip) => chip !== name));
  };

  const addNewTribe = (e: React.KeyboardEvent<HTMLElement>) => {
    const { code, target } = e;
    if (code === 'Enter') {
      const newChip = [...tribes];
      if (target) {
        newChip.push((target as HTMLInputElement).value);
      }
      setTribesHandler(newChip);
      setTextFieldValue('');
    }
  };

  const setTextFieldValueHandler = (e: React.ChangeEvent<HTMLElement>) => {
    setTextFieldValue((e.target as HTMLInputElement).value);
  };

  return (
    <Stack>
      <TextField
        label={t('formFields.tribesInput')}
        onKeyDown={addNewTribe}
        onChange={setTextFieldValueHandler}
        value={textFieldValue}
      ></TextField>
      {!!tribes.length && (
        <Paper
          sx={{
            flexWrap: 'wrap',
            display: 'flex',
            listStyle: 'none',
            p: '5px',
            marginTop: '15px',
          }}
          component="div"
        >
          {tribes.map((data, i) => {
            return (
              <Chip
                key={i}
                label={data}
                onDelete={handleDelete(data)}
                style={{ margin: 5 }}
              />
            );
          })}
        </Paper>
      )}
    </Stack>
  );
}
