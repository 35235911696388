type SortDirection = 'asc' | 'desc';

export const handleClick = (name: string, selected: string[]) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected: string[] = [];

  if (selectedIndex === -1) {
    newSelected = [...selected, name];
  } else if (selectedIndex === 0) {
    newSelected = selected.slice(1);
  } else if (selectedIndex === selected.length - 1) {
    newSelected = selected.slice(0, -1);
  } else if (selectedIndex > 0) {
    newSelected = [
      ...selected.slice(0, selectedIndex),
      ...selected.slice(selectedIndex + 1),
    ];
  }

  return newSelected;
};

export function sortByColumn<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
>(arr: T[], column: keyof T, order: SortDirection = 'asc'): T[] {
  return arr.sort((a, b) => {
    const isAsc = order === 'asc';
    let valueA = a[column];
    let valueB = b[column];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return (isAsc ? 1 : -1) * valueA.localeCompare(valueB);
    } else if (Array.isArray(valueA) && Array.isArray(valueB)) {
      const separator = ',';
      valueA = valueA.join(separator);
      valueB = valueB.join(separator);
      return (isAsc ? 1 : -1) * valueA.localeCompare(valueB);
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      return (isAsc ? 1 : -1) * (valueA - valueB);
    } else {
      return 0;
    }
  });
}

export const handleSelectAllClick = (
  checked: boolean,
  arr: { id: string }[],
): string[] => (checked ? arr.map((n) => n.id) : []);
