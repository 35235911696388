import { styled } from '@mui/system';
import DashboardNavbar from 'layouts/DashboardNavbar';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import DashboardSidebar from './DashboardSidebar';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: '88px',
  paddingBottom: '20px',
});

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />

      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
