import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PopupProps = {
  open: boolean;
  type: 'delete' | 'confirm';
  onConfirm: () => void;
  handleClose: () => void;
};

const Popup = ({ open, type, onConfirm, handleClose }: PopupProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
          padding: '16px',
        },
      }}
    >
      <DialogTitle>
        {t('popupDialog.title', {
          type:
            type === 'confirm'
              ? t('popupDialog.save')
              : t('popupDialog.delete'),
        })}
      </DialogTitle>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('popupDialog.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          sx={{ textTransform: 'capitalize' }}
        >
          {type === 'delete'
            ? t('popupDialog.deleteButton')
            : t('popupDialog.saveButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
