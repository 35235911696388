import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Container, Grid, Typography } from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import SnackbarContext from 'contexts/snackbarContext';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteChallenge, getChallenges } from 'services/challenge.service';
import { ChallengeType } from 'types/challenges.type';

import {
  ChallengeCard,
  ContainerGrid,
  DetailText,
  Header,
  smallPadding,
  StyledGrid,
  Title,
} from './ChallgenesPage.style';

export default function ChallengesPage() {
  const { t, i18n } = useTranslation();
  const { handleOpen } = useContext(SnackbarContext);
  const [challengeList, setChallengeList] = useState<ChallengeType[] | null>(
    null,
  );
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    getChallengeList();
  }, []);

  const getChallengeList = () => {
    getChallenges().then(({ data }) => setChallengeList(data));
  };

  const deleteChallengeHandler = () => {
    deleteChallenge(selectedId).then(() => {
      setOpenModal(false);
      handleOpen('Challenge successfully deleted', 'success');
      getChallengeList();
    });
  };

  const navigateChallengeDetails = (id: string) => {
    navigate(id);
  };

  const openModalHandler = (id: string) => {
    setOpenModal(true);
    setSelectedId(id);
  };

  return (
    <Container>
      <PageHeader
        title={t('challengesPage.title')}
        hasButton
        buttonTitle={t('challengesPage.buttonTitle').toString()}
      />
      <ContainerGrid container>
        {challengeList?.length ? (
          challengeList.map(
            ({
              id,
              name_HU,
              name_EN,
              shortDescription_HU,
              shortDescription_EN,
              longDescription_HU,
              longDescription_EN,
              startDate,
              endDate,
              currentScore,
              targetScore,
              organisation,
            }) => (
              <StyledGrid item lg={4} md={6} sm={12} key={id}>
                <ChallengeCard>
                  <Header>
                    <EditIcon onClick={() => navigateChallengeDetails(id)} />
                    <Typography>{organisation.companyName}</Typography>
                    <DeleteIcon onClick={() => openModalHandler(id)} />
                  </Header>
                  <Title>{i18n.language === 'en' ? name_EN : name_HU}</Title>
                  <Grid style={smallPadding}>
                    <DetailText>
                      {t('challengesPage.shortDescription')}
                    </DetailText>
                    <Typography>
                      {i18n.language === 'en'
                        ? shortDescription_EN
                        : shortDescription_HU}
                    </Typography>
                  </Grid>
                  <Grid style={smallPadding}>
                    <DetailText>
                      {t('challengesPage.longDescription')}
                    </DetailText>
                    <Typography>
                      {i18n.language === 'en'
                        ? longDescription_EN
                        : longDescription_HU}
                    </Typography>
                  </Grid>
                  <Header>
                    <Grid>
                      <DetailText>{t('challengesPage.startDate')}</DetailText>
                      <Typography>
                        {format(parseISO(startDate), 'yyyy-MM-dd')}
                      </Typography>
                    </Grid>
                    <Grid>
                      <DetailText>{t('challengesPage.endDate')}</DetailText>
                      <Typography>
                        {format(parseISO(endDate), 'yyyy-MM-dd')}
                      </Typography>
                    </Grid>
                  </Header>
                  <Grid style={smallPadding}>
                    <DetailText>{t('challengesPage.score')}</DetailText>
                    <Typography>
                      {currentScore}/{targetScore}
                    </Typography>
                  </Grid>
                </ChallengeCard>
              </StyledGrid>
            ),
          )
        ) : (
          <Container style={{ padding: 0 }}>
            {t('challengesPage.noChallenges')}
          </Container>
        )}
      </ContainerGrid>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteChallengeHandler}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
